import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import SubMenu from "./components/SubMenu";

const MainMenu = () => {
  /* eslint-disable no-unused-vars */

  return (
    <StaticQuery
      query={graphql`
        query mainMenu {
          wordpressWpApiMenusMenusItems(slug: { eq: "main" }) {
            items {
              title
              object_slug
              wordpress_children {
                title
                object_id
                object_slug
              }
            }
          }
        }
      `}
      render={data => (
        <div className="header__menu">
          <nav className="relative">
            <div className="menu-main-container">
              <ul className="menu-main">
                {data.wordpressWpApiMenusMenusItems.items.map(item => (
                  <li className="menu-item" key={item.object_slug}>
                    <Link to={`/${item.object_slug}`} activeClassName="active">
                      {item.title}
                    </Link>
                    {item.wordpress_children ? (
                      <SubMenu items={item.wordpress_children} parentSlug={item.object_slug} />
                    ) : null}
                  </li>
                ))}
                <li className="menu-item">
                  <Link to="/contact/">
                    <button type="button" className="btn__cta btn__cta--green" id="contactUs">
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      Let's talk!
                    </button>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      )}
    />
  );
};

export default MainMenu;
