import axios from "axios";

const fetchForm = (data, url, resetForm) => {
  let formData = data;

  if (data instanceof FormData === false) {
    formData = new FormData();

    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
  }

  return axios({
    method: "post",
    url,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" }
  })
    .then(r => {
      let type = "wpcf7-error";
      if (r.data.status === "mail_sent") {
        type = "wpcf7-mail-sent-ok";
        resetForm();
      }
      return { type, message: r.data.message };
    })
    .catch(err => {
      console.log(err);
      return {
        type: "wpcf7-error",
        message: "There is an error. Please try sending again."
      };
    });
};

export default fetchForm;
