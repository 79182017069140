import { Link } from "gatsby";
import React from "react";
import "lazysizes";
import MainMenu from "../modules/Menu/Menu";
import MobileMenu from "../modules/MobileMenu/MobileMenu";
import PopupForm from "../modules/PopupForm/PopupForm";
import TopPopup from "../modules/TopPopup/TopPopup";
import "../styles/styles.scss";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolling: false
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (window.scrollY === 0 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
    } else if (window.scrollY !== 0 && this.state.scrolling !== true) {
      this.setState({ scrolling: true });
    }
  }

  render() {
    return (
      <React.Fragment>
        <PopupForm />
        <header className={`header ${this.state.scrolling ? "sticky" : ""}`}>
          <TopPopup />
          <div className="container">
            <div className="header__wrapper">
              <div className="header__logo">
                <Link className="logo" href="/">
                  <object
                    className="header-logo img-responsive"
                    /* eslint-disable-next-line global-require */
                    data={require("../images/logo.svg")}
                    type="image/svg+xml"
                  >
                    PandaGroup | e-commerce development
                  </object>
                </Link>
              </div>
              <MainMenu />
              <MobileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default Header;
