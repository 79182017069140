import React from "react";
import { Link } from "gatsby";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { MainContext } from "../../containers/MainContext";

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      subMenuActive: false
    };
    this.openMobileMenu = this.openMobileMenu.bind(this);
    this.openSubMenu = this.openSubMenu.bind(this);
  }

  openMobileMenu() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  }

  openSubMenu() {
    const currentState = this.state.subMenuActive;
    this.setState({ subMenuActive: !currentState });
  }

  /* eslint-disable class-methods-use-this, no-unused-vars */
  handleShowPopup(p) {
    const [popup, setPopup] = p;
    setPopup(pop => ({ ...pop, show: true }));
  }
  /* eslint-enable class-methods-use-this, no-unused-vars */

  render() {
    /* eslint-disable no-unused-vars */
    return (
      <MainContext.Consumer>
        {props => (
          <div className="menu__mobile">
            <div className="burger-icon">
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
              <div
                className={`burger-icon__text ${this.state.active ? "open-h" : ""}`}
                onClick={this.openMobileMenu}
              >
                MENU
              </div>
              <button
                type="button"
                onClick={this.openMobileMenu}
                className={`hamburger ${this.state.active ? "open-h" : ""}`}
              >
                <div className="line line1" />
                <div className="line line2" />
                <div className="line line3" />
              </button>
            </div>
            <div className={`mobile-overlay ${this.state.active ? "open-h" : ""}`}>
              <div className="site-logo mobile-header__logo">
                <Link className="logo" to="/">
                  <object
                    className="header-logo img-responsive"
                    /* eslint-disable-next-line global-require */
                    data={require("../../images/logo.svg")}
                    type="image/svg+xml"
                  >
                    PandaGroup | e-commerce development
                  </object>
                </Link>
              </div>
              <div className="menu-main-container">
                <ul id="menu-main" className="mobile__menu">
                  <li
                    id="menu-item-21"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-21"
                  >
                    <Link to="/services/">Services</Link>
                    <ul
                      className={`sub-menu ${this.state.subMenuActive ? "subMenuMobileShow" : ""}`}
                    >
                      <li
                        id="menu-item-1335"
                        className="menu-item menu-item-type-post_type menu-item-object-services menu-item-1335"
                      >
                        <Link to="/services/magento-ecommerce-development-services">
                          Magento Development
                        </Link>
                      </li>
                      <li
                        id="menu-item-1336"
                        className="menu-item menu-item-type-post_type menu-item-object-services menu-item-1336"
                      >
                        <Link to="/services/magento-pwa-development">Magento PWA</Link>
                      </li>

                      <li
                        id="menu-item-1338"
                        className="menu-item menu-item-type-post_type menu-item-object-services menu-item-1338"
                      >
                        <Link to="/services/magento-extension-development">Magento Extensions</Link>
                      </li>
                      <li
                        id="menu-item-1339"
                        className="menu-item menu-item-type-post_type menu-item-object-services menu-item-1339"
                      >
                        <Link to="/services/magento-design-agency/">Magento Design</Link>
                      </li>
                      <li
                        id="menu-item-1337"
                        className="menu-item menu-item-type-post_type menu-item-object-services menu-item-1337"
                      >
                        <Link to="/services/magento-aws-hosting/">Magento Hosting</Link>
                      </li>
                      <li
                        id="menu-item-1342"
                        className="menu-item menu-item-type-post_type menu-item-object-services menu-item-1342"
                      >
                        <Link to="/services/magento-automation-testing/">Magento Testing</Link>
                      </li>
                      {/* <li */}
                      {/*  id="menu-item-1340" */}
                      {/*  className="menu-item menu-item-type-post_type menu-item-object-services menu-item-1340" */}
                      {/* > */}
                      {/*  <Link to="/services/migrations-to-magento/">Migrations to Magento</Link> */}
                      {/* </li> */}
                      {/* <li */}
                      {/*  id="menu-item-1341" */}
                      {/*  className="menu-item menu-item-type-post_type menu-item-object-services menu-item-1341" */}
                      {/* > */}
                      {/*  <Link to="/services/migrations-magento-1-to-2/"> */}
                      {/*    Migrations Magento 1 to 2 */}
                      {/*  </Link> */}
                      {/* </li> */}
                      {/* <li */}
                      {/*  id="menu-item-1343" */}
                      {/*  className="menu-item menu-item-type-post_type menu-item-object-services menu-item-1343" */}
                      {/* > */}
                      {/*  <Link to="/services/server-monitoring/">Server monitoring</Link> */}
                      {/* </li> */}
                    </ul>
                    <button
                      type="button"
                      onClick={this.openSubMenu}
                      className={`${this.state.subMenuActive ? "current" : ""}`}
                    >
                      {this.state.subMenuActive ? "-" : "+"}
                    </button>
                  </li>
                  <li
                    id="menu-item-18"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-18"
                  >
                    <Link to="/case-study/">Case Study</Link>
                  </li>
                  <li
                    id="menu-item-747"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-747"
                  >
                    <Link to="/blog/">Blog</Link>
                  </li>
                  <li
                    id="menu-item-232"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-232"
                  >
                    <Link to="/about/">About</Link>
                  </li>
                  <li
                    id="menu-item-16"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-16"
                  >
                    <Link to="/contact/">Contact</Link>
                  </li>
                  <li
                    id="menu-item-233"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-233"
                  >
                    <Link to="/join-us/">Join Us</Link>
                  </li>
                  <li>
                    <Link to="/contact/">
                      <button type="button" className="btn__cta btn__cta--green" id="contactUs">
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        Let's talk!
                      </button>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="header__socials">
                <a href="https://www.facebook.com/PandaGroupdev/">
                  <button type="button" className="fa fa-2x fa-facebook social-icon">
                    <FaFacebookF />
                  </button>
                </a>
                <a href="https://twitter.com/PandaGroupdev">
                  <button type="button" className="fa fa-2x fa-twitter social-icon">
                    <FaTwitter />
                  </button>
                </a>
                <a href="https://www.linkedin.com/company/pandagroupdev/">
                  <button type="button" className="fa fa-2x fa-linkedin social-icon">
                    <FaLinkedinIn />
                  </button>
                </a>
              </div>
            </div>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div
              className={`header__overlay ${this.state.active ? "show" : ""}`}
              id="header-overlay"
              onClick={this.openMobileMenu}
            />
          </div>
        )}
      </MainContext.Consumer>
    );
  }
}

export default MobileMenu;
