import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import fetchForm from "../../../helpers/fetchForm";

const ContactSchema = Yup.object().shape({
  "your-name": Yup.string().required("The field is required"),
  "your-message": Yup.string().required("The field is required"),
  "your-email": Yup.string()
    .email("The e-mail address is invalid.")
    .required("The field is required")
});

const ContactForm = ({ cssClass }) => {
  const [message, setMessage] = useState({ type: "", message: "" });
  const [animate, setAnimate] = useState("");
  const [activeField, setActiveField] = useState({
    yourName: "",
    yourEmail: "",
    yourMessage: ""
  });

  useEffect(() => {
    setAnimate("animate");
    setTimeout(() => {
      setAnimate("");
      setMessage({ type: "", message: "" });
    }, 4000);
  }, [message.type]);

  return (
    <div className="form__wrapper">
      <Formik
        initialValues={{
          "your-name": "",
          "your-email": "",
          "your-message": "",
          website: "",
          _wpcf7: "350",
          _wpcf7_version: "5.1.1",
          _wpcf7_locale: "en_US",
          _wpcf7_unit_tag: "wpcf7-f350-p11-o1",
          _wpcf7_container_post: "11",
          "g-recaptcha-response": ""
        }}
        validationSchema={ContactSchema}
        onSubmit={(values, { resetForm }) => {
          // eslint-disable-next-line no-undef
          const url = `${REACT_APP_API_DOMAIN}wp-json/contact-form-7/v1/contact-forms/350/feedback`;
          fetchForm(values, url, resetForm).then(r => {
            setMessage(r);
          });
        }}
      >
        {({ errors, touched }) => (
          <Form className={`form form__footer form--${cssClass}`}>
            {/* eslint-disable jsx-a11y/label-has-for */}
            <label
              htmlFor="your-name"
              id="firstNameFieldLabel"
              className={`form-field__wrapper ${activeField.yourName}`}
            >
              Your name<span>*</span>
              <span className="form-field your-name">
                <Field
                  name="your-name"
                  id="your-name"
                  type="text"
                  size="40"
                  aria-required="true"
                  className="form-field__input"
                  placeholder="e.g. John Doe"
                  onFocus={() => setActiveField({ ...activeField, yourName: "active" })}
                  onBlur={() => setActiveField({ ...activeField, yourName: "" })}
                />
                {errors["your-name"] && touched["your-name"] ? (
                  <span role="alert" className="form-field__error">
                    {errors["your-name"]}
                  </span>
                ) : null}
              </span>
            </label>
            {/* eslint-enable jsx-a11y/label-has-for */}
            {/* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
            <label
              htmlFor="your-email"
              id="emailFieldLabel"
              className={`form-field__wrapper ${activeField.yourEmail}`}
            >
              Your e-mail<span>*</span>
              <span className="form-field your-email">
                <Field
                  name="your-email"
                  id="your-email"
                  type="email"
                  size="40"
                  aria-required="true"
                  className="form-field__input"
                  placeholder="e.g. john@gmail.com"
                  onFocus={() => setActiveField({ ...activeField, yourEmail: "active" })}
                  onBlur={() => setActiveField({ ...activeField, yourEmail: "" })}
                />
                {errors["your-email"] && touched["your-email"] ? (
                  <span role="alert" className="form-field__error">
                    {errors["your-email"]}
                  </span>
                ) : null}
              </span>
            </label>
            {/* eslint-enable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
            {/* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
            <label
              htmlFor="your-message"
              id="messageFieldLabel"
              className={`form-field__wrapper ${activeField.yourMessage}`}
            >
              How can we help you?<span>*</span>
              <span className="form-field your-message">
                <Field
                  component="textarea"
                  name="your-message"
                  id="your-message"
                  size="40"
                  aria-required="true"
                  className="form-field__textarea"
                  placeholder="Please, describe your case"
                  onFocus={() => {
                    setActiveField({ ...activeField, yourMessage: "active" });
                  }}
                  onBlur={() => {
                    setActiveField({ ...activeField, yourMessage: "" });
                  }}
                />
                {errors["your-message"] && touched["your-message"] ? (
                  <span role="alert" className="form-field__error">
                    {errors["your-message"]}
                  </span>
                ) : null}
              </span>
            </label>
            {/* eslint-enable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
            <div className="form-submit__wrapper">
              <p className="form-info">*We usually reply within 1-2 days</p>
              <button
                type="submit"
                className="form-submit btn__cta btn__cta--green btn__cta--arrow"
              >
                Send message
              </button>
            </div>
            <div
              className={`form-message ${message.type} ${message.type === "wpcf7-mail-sent-ok" &&
                "mail-contact-sent"} ${animate}`}
            >
              {message.message}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default ContactForm;
