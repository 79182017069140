import React from "react";
import Popup from "../../components/Popup/Popup";
import ContactForm from "../Contact/components/ContactForm20";

const PopupForm = ({ showPopup, setVal }) => {
  const handleShowForm = v => {
    setVal(v);
  };

  return (
    <Popup showPopup={showPopup} handleShowPopup={handleShowForm}>
      <div className="popup--form">
        <div className="popup--form__image">
          <header className="popup__header--sub">
            <h2>
              Panda is waiting <strong>to help you</strong>
            </h2>
          </header>
        </div>
        <div className="form__wrapper--popup">
          <header className="popup__header--sub">
            <h2>
              Any questions? <strong>Write us!</strong>
            </h2>
          </header>
          <ContactForm cssClass="popup" />
        </div>
      </div>
    </Popup>
  );
};
export default PopupForm;
