import React from "react";
import cookie from "react-cookies";

class TopPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popupCookie: cookie.load("top-popup") || false,
      displayed: true
    };

    this.checkCookie = this.checkCookie.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentDidMount() {
    this.checkCookie();
  }

  checkCookie() {
    const { popupCookie } = this.state;

    if (popupCookie === false || popupCookie === "false" || popupCookie === undefined) {
      this.addCookie();
      this.setState({ displayed: false });
    } else {
      this.setState({ displayed: true });
    }
  }

  /* eslint-disable class-methods-use-this, no-unused-vars */
  addCookie() {
    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
    cookie.save("top-popup", true, { path: "/", expires, maxAge: 1000 });
  }

  closePopup() {
    this.setState({ displayed: true });
  }

  render() {
    const { displayed } = this.state;

    if (displayed === true) {
      return "";
    }

    return (
      <div className="top-popup popup">
        <div className="container">
          <p>
            <a href="/core-web-vitals">
              Check how to optimize eCommerce and rise CTR.
              <button type="button">Get a free Magento Ebook!</button>
            </a>
          </p>
          <button type="button" className="popup__close" onClick={this.closePopup} />
        </div>
        <div className="top-popup__background" />
      </div>
    );
  }
}

export default TopPopup;
