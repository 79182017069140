import React from "react";
import { StaticQuery, graphql } from "gatsby";

const OpeningHours = ({ cssClass = "" }) => (
  <StaticQuery
    query={graphql`
      query openingHoursQuery {
        allWordpressPage(filter: { template: { eq: "page-contact.php" } }) {
          edges {
            node {
              template
            }
          }
        }
        allWordpressAcfPages {
          edges {
            node {
              id
              acf {
                address
                street
                e_mail
                phone
                openning_hours
                our_time
              }
            }
          }
        }
      }
    `}
    render={data => {
      const contactPageData = (data.allWordpressAcfPages.edges[10].node.acf || {}).openning_hours;
      return (
        <div
          className={`${cssClass}contact__item contact__time`}
          dangerouslySetInnerHTML={{
            __html:
              contactPageData ||
              "<p>Monday-Friday: <strong>8 A.M. to 4 P.M.</strong><br>Saturday-Sunday: Closed</p>"
          }}
        />
      );
    }}
  />
);

export default OpeningHours;
