import React from "react";
import { Link } from "gatsby";

const SubMenu = props => {
  return (
    <ul className="sub-menu">
      {props.items.map(item => (
        <li className="menu-item" key={item.object_id}>
          <Link to={`/${props.parentSlug}/${item.object_slug}`}>{item.title}</Link>
        </li>
      ))}
    </ul>
  );
};

export default SubMenu;
