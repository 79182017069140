import React, { useEffect, useState } from "react";
import NoSSR from "react-no-ssr";

const ContactTimer = () => {
  const timePoland = new Date(new Date().toLocaleString("en-US", { timeZone: "Europe/Warsaw" }));
  const timeOffsetPoland = timePoland.getTimezoneOffset();
  const serv = 1;

  const timeZonePoland = timeOffsetPoland * -60;
  const newDate = new Date();
  const dateDifference = newDate.getTimezoneOffset() * -60;

  let flag = true;

  const [zone, setZone] = useState(true);

  useEffect(() => {
    /* eslint-disable no-unused-expressions */
    dateDifference === timeZonePoland
      ? setZone(" We are in the same time zone.")
      : setZone(" We are not in the same time zone.");
    /* eslint-enable no-unused-expressions */
  }, []);

  /* eslint-disable no-unused-vars, no-shadow */

  const [timer, setTimer] = useState(0);

  const [completeTime, setCompleteTime] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      const offset = timeZonePoland === 7200 ? 2 : 1;

      const daataa = new Date(new Date().getTime() + offset * 3600 * 1000)
        .toUTCString()
        .replace(/ GMT$/, "");

      const matches = daataa.match(/(([0-9]+:)+)\w+/);
      const matchesWithoutHour = daataa.match(/((:+[0-9]+:)+)\w+/);
      const time = matches[0];
      let hours = time.match(/([0-9])\w+/);
      // eslint-disable-next-line radix
      const ampm = parseInt(hours) > 12 ? " PM" : " AM";
      // eslint-disable-next-line radix
      hours = parseInt(hours[0]) <= 12 ? hours[0] : hours[0] - 12;

      if (flag) {
        setTimer(serv * 1000);
      }

      const temp = hours + matchesWithoutHour[0] + ampm;

      setCompleteTime(timeZonePoland === 7200 ? `${temp} UTC/GMT+2` : `${temp} UTC/GMT+1`);

      flag = false;

      setTimer(timer => timer + 1000);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  /* eslint-enable no-unused-vars, no-shadow */

  return (
    <NoSSR>
      <div className="contact__block">
        <h3 className="contact__heading">
          Our time: &nbsp;
          <span id="timer" className="contact__clock">
            {completeTime}
          </span>
        </h3>
        <span className="zone">{zone}</span>
      </div>
    </NoSSR>
  );
};

export default ContactTimer;
