import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../../containers/MainContext";

const Popup = ({ children }) => {
  /* eslint-disable no-unused-vars */
  const [show, setShow] = useState("unset");
  const [popup, setPopup] = useContext(MainContext);
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    if (popup.show) {
      setShow("active");
      setTimeout(() => setShow("active animate"), 200);
    }
    if (!popup.show && show !== "unset") {
      setShow("active");
      setTimeout(() => setShow(""), 450);
    }
  }, [popup]);

  const closePopup = () => {
    setPopup(false);
  };

  useEffect(() => {
    document.addEventListener("click", e => {
      const $popupInner = document.querySelector(".popup__inner");

      if (
        $popupInner &&
        !$popupInner.contains(e.target) &&
        !popup.show &&
        e.target.id !== "hireUs" &&
        e.target.id !== "hireUsCTA"
      ) {
        closePopup();
      }
    });
  }, []);

  return (
    <div className={`popup__overlay ${show}`}>
      <div className="popup__inner">
        <button type="button" className="popup__close" onClick={() => closePopup()} />
        <div className="popup__content">{children}</div>
      </div>
    </div>
  );
};
export default Popup;
